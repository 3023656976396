const POSITION_DEFAULT = 0;

const useTabIndicator = (tabOffset: Ref, initiallyVisible = true) => {
  const tabsRef = ref<HTMLElement>();
  const useTransition = ref(false);

  const leftActive = ref(0);
  const widthActive = ref(0);

  const leftHover = ref<number | null>(null);
  const widthHover = ref<number | null>(null);

  const visibility = ref<'hidden' | 'visible'>(initiallyVisible
    ? 'visible'
    : 'hidden');

  const uiTabsClass = computed(() => ({ 'ui-tabs--use-transition': useTransition.value }));

  const tabIndicatorStyle = computed(() => ({
    left: `${leftHover.value ?? leftActive.value}px`,
    marginLeft: `${tabOffset.value}px`,
    visibility: visibility.value,

    width: `${(widthHover.value ?? widthActive.value) - tabOffset.value * 2}px`,
  }));

  const getElementLeftAndWidth = (element: HTMLElement) => ({
    left: element instanceof Element
      ? element.offsetLeft
      : POSITION_DEFAULT,
    width: element instanceof Element
      ? element.offsetWidth
      : POSITION_DEFAULT,
  });

  const setVisibilityIndicator = (isVisible: boolean) => {
    if (initiallyVisible) {
      return;
    }
    visibility.value = isVisible
      ? 'visible'
      : 'hidden';
  };

  const unsetLeftAndWidth = () => {
    leftHover.value = null;
    widthHover.value = null;
  };

  const updateActive = (element: HTMLElement) => {
    const {
      left,
      width,
    } = getElementLeftAndWidth(element);

    leftActive.value = left;
    widthActive.value = width;
  };

  const updateHover = (element: HTMLElement) => {
    const {
      left,
      width,
    } = getElementLeftAndWidth(element);

    leftHover.value = left;
    widthHover.value = width;
    setVisibilityIndicator(true);
  };

  const updateUseTransition = (shouldBeUsed: boolean) => {
    useTransition.value = shouldBeUsed;
  };

  const onClick = (event: MouseEvent) => {
    updateActive(event.currentTarget as HTMLInputElement);
  };

  const onMouseEnter = (event: MouseEvent) => {
    updateHover(event.currentTarget as HTMLInputElement);
    setVisibilityIndicator(true);
  };

  const onMouseLeave = () => {
    leftHover.value = null;
    widthHover.value = null;
    setVisibilityIndicator(false);
  };

  const updateActiveElement = () => {
    if (tabsRef.value) {
      const tabElements = tabsRef.value.getElementsByClassName('filter-tab-active');
      if (tabElements.length) {
        updateActive(tabElements[0] as HTMLElement);
      }
    }
  };

  onMounted(() => {
    updateActiveElement();

    nextTick(() => {
      useTransition.value = true;
    });
  });

  return {
    onClick,
    onMouseEnter,
    onMouseLeave,
    setVisibilityIndicator,
    tabIndicatorStyle,
    tabsRef,
    uiTabsClass,
    unsetLeftAndWidth,
    updateActiveElement,
    updateHover,
    updateUseTransition,
  };
};

export { useTabIndicator };
