import { State } from '@/config/state';

const useModalVisible = () => {
  const isModalVisible = useState<boolean>(State.IsModalVisible, () => false);

  const setIsModalVisible = (isVisible: boolean) => {
    isModalVisible.value = isVisible;
  };

  return {
    isModalVisible,
    setIsModalVisible,
  };
};

export { useModalVisible };
