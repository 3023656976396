import { type ProductType } from 'configs';
import {
  type AlgoliaFilterFacetGroup,
  DesignType,
} from '#gql/default';
import {
  DESIGN_TYPE,
  filterCategories,
  filterCategoryNames,
  SEARCH_PARAM,
} from '@/config/filter';
import { State } from '@/config/state';
import type {
  Filter,
  FilterCategoryName,
  FilterCategoryWithFacets,
  FilterGroup,
  SearchParameter,
} from '@/types/list-page';

const useActiveFilters = () => {
  const activeFilters = useState<FilterCategoryWithFacets>(State.ActiveFilters);

  const currentFilterGroups = useState<FilterGroup[]>(State.CurrentFilterGroups);

  const searchParams = useState<SearchParameter>(State.SearchParams, () => ({
    productType: null,
    query: null,
  }));

  const selectedDesignType = useState<DesignType>(State.SelectedDesignType, () => DesignType.ALL);

  const isFiltersListHidden = useState<boolean>(State.IsFiltersListHidden, () => false);

  const isFiltersListHiddenMobile = useState<boolean>(State.IsFiltersListHiddenMobile, () => true);

  const filtersSortedByInitialSelection = useState<FilterGroup[]>(State.SortedFilters, () => []);

  const router = useRouter();

  const { getColorsForProductType } = useProductData();

  const { filterScaffold } = useFilterScaffold();

  const { scrollToSection } = useScrollTo();

  const { setCurrentPage } = usePagination();

  const getActiveFilters = computed(() => {
    const filters = { ...activeFilters.value };

    if (Array.isArray(filters.productColor)) {
      filters.productColor = filters.productColor.map((color) => (color.startsWith('#')
        ? color.substring(1)
        : color));
    }

    return filters;
  });

  const getSearchParams = computed(() => searchParams.value);

  const getSelectedDesignType = computed(() => selectedDesignType.value);

  const getIsFiltersListHidden = computed(() => isFiltersListHidden.value);

  const getIsFiltersListHiddenMobile = computed(() => isFiltersListHiddenMobile.value);

  const getCurrentFilterGroups = computed(() => currentFilterGroups.value);

  const getActiveProductTypesForFilterGroups = (filterGroups: FilterGroup[]): ProductType[] | null => {
    if (getActiveFilters.value?.product?.length === 0) {
      return null;
    }

    const productFilterGroup = filterGroups?.find((group) => group.key === 'product');

    if (!productFilterGroup) {
      return null;
    }

    return productFilterGroup.filters
      .filter((filter) => isNestedFilter(filter))
      .map((filter) => filter.subItems.filter((subItem) => subItem.isSelected))
      .flat()
      .map((subItem) => subItem.key) as ProductType[];
  };

  const getActiveProductTypes = computed<ProductType[] | null>(() => getActiveProductTypesForFilterGroups(getCurrentFilterGroups.value));

  const setIsFiltersListHidden = (hidden: boolean) => {
    isFiltersListHidden.value = hidden;
    isFiltersListHiddenMobile.value = hidden;
  };

  const setSelectedDesignType = (newSelectedDesignType: DesignType) => {
    selectedDesignType.value = newSelectedDesignType;
  };

  const setCurrentFilterGroups = (filterGroups: FilterGroup[]) => {
    currentFilterGroups.value = filterGroups;
  };

  const isFilterSelected = (filter: Filter): boolean => !!filter.isSelected
    || (isNestedFilter(filter) && filter.subItems.some((subItem) => subItem.isSelected));

  const updateSearchParams = (removeAllFilterQueryParams?: boolean) => {
    if (removeAllFilterQueryParams) {
      const query = { ...router.currentRoute.value.query };
      filterCategoryNames.forEach((filterCategoryName) => {
        delete query[filterCategoryName];
      });

      router.replace({ query });

      return;
    }

    router.replace({
      query: {
        ...router.currentRoute.value.query,
        ...getActiveFilters.value,
        page: '1',
      },
    });
  };

  const createFilterGroupsWithUpdatedProductColorFilters = (
    filterGroups: FilterGroup[],
    activeProductTypes: ProductType[] | null,
  ): FilterGroup[] => {
    if (!activeProductTypes) {
      return filterGroups;
    }
    const productColors = getColorsForProductType(activeProductTypes?.[0]);

    if (!productColors) {
      return filterGroups;
    }

    const productColorGroupIndex = filterGroups.findIndex((group) => group.key === 'productColor');
    const newProductColorGroup = {
      ...filterGroups[productColorGroupIndex],
      filters: productColors.map((color) => ({
        isSelected: getActiveFilters.value?.productColor?.includes(color),
        key: color,
      })),
    };

    const updatedFilterGroups = [ ...filterGroups ];
    updatedFilterGroups[productColorGroupIndex] = newProductColorGroup;

    return updatedFilterGroups;
  };

  const shouldProductColorFilterBeHidden = (): boolean => {
    const selectedProductsColors = getActiveFilters.value?.product?.map((product) => getColorsForProductType(product));
    if (selectedProductsColors?.some((colors) => !colors)) {
      return true;
    }

    // check if all selected products have exactly the same color values
    const allColors = new Set(selectedProductsColors?.flat());

    if (allColors.size === 0) {
      return true;
    }

    return !selectedProductsColors?.every((color) => {
      const colorSet = new Set(color);
      return colorSet.size === allColors.size
        && [ ...allColors ].every((str) => colorSet.has(str as string));
    });
  };

  const shouldFilterGroupBeHidden = (filterCategoryName: FilterCategoryName) => {
    const productsForFilter = filterCategories.find((filterCategory) => filterCategory.name === filterCategoryName)?.products;
    if (!productsForFilter) {
      return false;
    }

    if (getActiveFilters.value?.product?.length === 0) {
      return true;
    }

    const areIncompatibleProductsSelected = getActiveFilters.value?.product
      ?.some((product) => !productsForFilter.includes(product as ProductType));

    return filterCategoryName === 'productColor'
      ? shouldProductColorFilterBeHidden()
      : areIncompatibleProductsSelected;
  };

  const setActiveFilters = (filters: FilterCategoryWithFacets, shouldUpdateSearchParams?: boolean) => {
    activeFilters.value = filters;
    if (shouldProductColorFilterBeHidden()) {
      activeFilters.value.productColor = [];
    }

    if (shouldUpdateSearchParams) {
      updateSearchParams();
    }
  };

  const setSearchParams = (param: string | { productType?: string | null; query?: string | null }, type?: 'query' | 'productType') => {
    if (typeof param === 'string') {
      if (type === 'query') {
        searchParams.value = {
          productType: searchParams?.value?.productType
            ? searchParams?.value.productType
            : null,
          query: param,
        };
      } else if (type === 'productType') {
        searchParams.value = {
          productType: param,
          query: searchParams?.value?.query
            ? searchParams?.value.query
            : null,
        };
      }
    } else {
      searchParams.value = {
        productType: param.productType
          ? param.productType
          : null,
        query: param.query
          ? param.query
          : null,
      };
    }
  };

  const updateFilters = (filter: Filter, filterGroupKey: FilterCategoryName, sortSelectedFilters?: boolean): Filter => {
    // select filter and subItems if filter is active
    if (getActiveFilters.value?.[filterGroupKey]?.includes(filter.key)) {
      return {
        ...filter,
        ...(isNestedFilter(filter) && {
          subItems: filter.subItems.map((subItem) => ({
            ...subItem,
            isSelected: true,
          })),
        }),
        isSelected: true,
      };
    }

    // select subItem if only subItem is active
    if (isNestedFilter(filter)) {
      const updatedSubItems = filter.subItems.map((subItem) => {
        if (getActiveFilters.value?.[filterGroupKey]?.includes(subItem.key)) {
          return {
            ...subItem,
            isSelected: true,
          };
        }

        return subItem;
      });

      return {
        ...filter,
        isSelected: false,
        subItems: sortSelectedFilters
          ? updatedSubItems.sort((a, b) => compareSortByCondition(a, b, isFilterSelected))
          : updatedSubItems,
      };
    }

    return filter;
  };

  const setFiltersSortedByInitialSelection = (filterGroups: FilterGroup[]) => {
    if (filtersSortedByInitialSelection.value.length) {
      return;
    }

    filtersSortedByInitialSelection.value = filterGroups.map((group) => ({
      ...group,
      filters: group.filters.map((filter) => ({
        ...filter,
        isSelected: null,
        subItems: isNestedFilter(filter)
          ? filter.subItems.map((subItem) => ({
            ...subItem,
            isSelected: null,
          }))
          : [],
      })),
    }));
  };

  const resetFiltersSortedByInitialSelection = () => {
    filtersSortedByInitialSelection.value = [];
  };

  const updateCurrentFilterGroupsAfterFilterWasToggled = (sortSelectedFilters?: boolean, useInitialFilters?: boolean) => {
    const filtersToUse = useInitialFilters
      ? filterScaffold.value
      : filtersSortedByInitialSelection.value;

    const newFilters: FilterGroup[] = filterCategories.map((filterCategory) => {
      const filters = filtersToUse.find((filter) => filter.key === filterCategory.name)?.filters ?? [];

      const updatedFilters = filters.map((filter) => updateFilters(filter, filterCategory.name));
      const sortedFilters = filterCategory.name === 'productColor' || filterCategory.name === 'designColor' || !sortSelectedFilters
        ? updatedFilters
        : updatedFilters.sort((a, b) => compareSortByCondition(a, b, isFilterSelected));

      return {
        filters: sortedFilters,
        isExpanded: filterCategory.isExpanded,
        isHidden: shouldFilterGroupBeHidden(filterCategory.name),
        key: filterCategory.name,
      };
    });

    const newActiveProductTypes = getActiveProductTypesForFilterGroups(newFilters);
    const newFilterGroupsWithUpdatedProductColorFilter: FilterGroup[] = createFilterGroupsWithUpdatedProductColorFilters(newFilters, newActiveProductTypes);

    setFiltersSortedByInitialSelection(newFilterGroupsWithUpdatedProductColorFilter);
    setCurrentFilterGroups(newFilterGroupsWithUpdatedProductColorFilter);
  };

  const toggleActiveFilters = ({
    filterCategoryName,
    filterKey,
    wasFilterSelected,
  }:{
    filterCategoryName: FilterCategoryName | typeof DESIGN_TYPE | typeof SEARCH_PARAM;
    filterKey: string;
    wasFilterSelected: boolean;
  }) => {
    if (filterCategoryName === DESIGN_TYPE) {
      setSelectedDesignType(DesignType.ALL);
      return;
    }

    if (filterCategoryName === SEARCH_PARAM) {
      router.replace({
        query: {
          ...router.currentRoute.value.query,
          ...{ q: [] },
        },
      });
      return;
    }

    const activeFiltersCopy = { ...activeFilters.value };
    const activeFiltersForCategory = activeFiltersCopy[filterCategoryName] ?? [];
    const filterGroup = getCurrentFilterGroups.value.find((group) => group.key === filterCategoryName);

    if (!filterGroup) {
      return;
    }

    // check if filterKey belongs to a subItem in a nested filter in this filterGroup
    const parentFilter = filterGroup.filters.find((filter) => isNestedFilter(filter)
      && filter.subItems.some((subItem) => subItem.key === filterKey));

    // process filter if it is a subItem
    if (parentFilter && isNestedFilter(parentFilter)) {
      // if filter is deselected, check if parentFilter was active, remove it and select all other subItems
      if (wasFilterSelected) {
        const parentFilterIsActive = activeFiltersForCategory.includes(parentFilter.key);

        activeFiltersCopy[filterCategoryName] = parentFilterIsActive
          ? [
            ...activeFiltersForCategory.filter((key) => key !== parentFilter.key),
            ...parentFilter.subItems?.filter((subItem) => subItem.key !== filterKey)
              .map((subItem) => subItem.key) ?? [],
          ]
          : activeFiltersForCategory.filter((key) => key !== filterKey);
      }

      // if filter is selected, check if all other subItems are selected, remove them and add parentFilter
      if (!wasFilterSelected) {
        const allSubItemsSelected = parentFilter.subItems?.filter((subItem) => subItem.key !== filterKey)
          .every((subItem) => activeFiltersForCategory.includes(subItem.key));

        activeFiltersCopy[filterCategoryName] = allSubItemsSelected
          ? [
            ...activeFiltersForCategory.filter((key) => !parentFilter.subItems?.map((subItem) => subItem.key).includes(key)),
            parentFilter.key,
          ]
          : [
            ...activeFiltersForCategory,
            filterKey,
          ];
      }
    }

    const filterToToggle = filterGroup.filters.find((filter) => filter.key === filterKey);

    // process NestedFilter
    if (!parentFilter && filterToToggle && isNestedFilter(filterToToggle)) {
      activeFiltersCopy[filterCategoryName] = wasFilterSelected
        ? activeFiltersForCategory.filter((key) => key !== filterKey)
        : [
          ...activeFiltersForCategory.filter((key) => !filterToToggle.subItems.map((subItem) => subItem.key).includes(key)),
          filterKey,
        ];
    }

    // if filter is BaseFilter on its own simply toggle it
    if (!parentFilter && filterToToggle && !isNestedFilter(filterToToggle)) {
      activeFiltersCopy[filterCategoryName] = wasFilterSelected
        ? activeFiltersForCategory.filter((key) => key !== filterKey)
        : [
          ...activeFiltersForCategory,
          filterKey,
        ];
    }

    setActiveFilters(activeFiltersCopy);
    updateCurrentFilterGroupsAfterFilterWasToggled();
    scrollToSection('design-listing-component');
    setCurrentPage(1);
    updateSearchParams();
  };

  const getFilterFacetsForListingPage = (): AlgoliaFilterFacetGroup[] => {
    const relevantSelectedFilters = getCurrentFilterGroups.value
      .filter((selectedFilter) => findFilterCategoryByKey(selectedFilter.key)?.isRelevantForSearchIndex)
      .map((filterGroup) => ({
        ...filterGroup,
        filters: filterGroup.filters.filter((filter) => filter.isSelected
        || (isNestedFilter(filter) && filter.subItems.some((subItem) => subItem.isSelected))),
      }))
      .filter((currentFilter) => currentFilter.filters.length > 0);

    const filterFacets: AlgoliaFilterFacetGroup[] = relevantSelectedFilters.reduce((acc, {
      filters: relevantFilters,
      key,
    }) => [
      ...acc,
      {
        key,
        value: relevantFilters.map((filter) => (filter.isSelected
          ? {
            key,
            value: [ filter.key ],
          }
          : {
            key: `${key}.${filter.key}`,
            value: ((isNestedFilter(filter) && filter.subItems.length)
              ? filter.subItems.filter((subItem) => subItem.isSelected)
                .map((subItem) => subItem.key)
              : []),
          })),
      },
    ], [] as AlgoliaFilterFacetGroup[]);

    return filterFacets;
  };

  const areAllFiltersEmpty = computed(() => {
    if (!getActiveFilters) {
      return true;
    }
    return Object.values(getActiveFilters.value).every((filters) => Array.isArray(filters) && filters.length === 0);
  });

  return {
    areAllFiltersEmpty,
    getActiveFilters,
    getActiveProductTypes,
    getCurrentFilterGroups,
    getFilterFacetsForListingPage,
    getIsFiltersListHidden,
    getIsFiltersListHiddenMobile,
    getSearchParams,
    getSelectedDesignType,
    resetFiltersSortedByInitialSelection,
    setActiveFilters,
    setCurrentFilterGroups,
    setIsFiltersListHidden,
    setSearchParams,
    setSelectedDesignType,
    toggleActiveFilters,
    updateCurrentFilterGroupsAfterFilterWasToggled,
    updateSearchParams,
  };
};

export { useActiveFilters };
