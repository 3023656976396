const useScrollTo = () => {
  const scrollToSection = (id: string) => {
    if (typeof document === 'undefined') {
      return;
    }

    const element = document.getElementById(id);
    const currentScrollPosition = document.getElementsByTagName('html')[0];

    if (element && (element?.offsetTop < currentScrollPosition.scrollTop)) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return { scrollToSection };
};

export { useScrollTo };
