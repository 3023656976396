const usePagination = () => {
  const router = useRouter();
  const route = useRoute();

  const currentPage = computed(() => {
    const page = parseInt(route.query.page as string, 10);
    return Math.max(Number.isNaN(page)
      ? 1
      : page, 1);
  });

  const setCurrentPage = (page: number) => {
    router.push({
      query: {
        ...route.query,
        page,
      },
    });
  };

  return {
    currentPage,
    setCurrentPage,
  };
};

export { usePagination };
